import React, { useState } from 'react'
import { string } from 'prop-types'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
import { useMutation } from 'react-apollo'
import { withSnackbar } from 'notistack'
import { withApollo } from 'react-apollo'
import stringifyErrors from '@helpers/stringifyErrors'
import { UPLOAD_REPORT_MUTATION } from '@queries/mutations'
import TextField from '@material-ui/core/TextField'
import { connect } from 'react-redux'
import { getReportData, captureReset } from '@redux/actions'

import './CaptureReportModal.scss'

const CaptureReportModal = ({
  projectCode,
  enqueueSnackbar,
  client,
  report,
  getReport,
  reset,
}) => {
  const [name, updateName] = useState('')

  const [reportMutation] = useMutation(UPLOAD_REPORT_MUTATION)

  const save = async e => {
    e.preventDefault()
    const storeData = await getReport()
    const saveData = { name, ...storeData }

    console.log('saveData', saveData)

    try {
      await reportMutation({ variables: saveData })

      enqueueSnackbar('Report Saved', {
        variant: 'success',
      })
      reset()
    } catch (e) {
      enqueueSnackbar(stringifyErrors(e.graphQLErrors), {
        variant: 'error',
      })
    }
  }

  return (
    <div>
      <DialogTitle>Set Name of Report Item</DialogTitle>

      <DialogContent>
        <form onSubmit={save}>
          <TextField
            label="Name"
            value={name}
            onChange={e => updateName(e.target.value)}
            margin="normal"
            type="text"
            required
          />

          <Button variant="contained" color="primary" type="submit">
            Save New Report Item
          </Button>
        </form>
      </DialogContent>
    </div>
  )
}

CaptureReportModal.propTypes = {
  code: string,
}

const mapStateToProps = ({ report }) => ({ report })

const mapDispatchToProps = d => ({
  getReport: () => d(getReportData()),
  reset: () => d(captureReset()),
})
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withSnackbar(withApollo(CaptureReportModal)))
