import React, { useState, Fragment } from 'react'
import PaddingBox from '@components/PaddingBox/PaddingBox'
import AdminSettings from '@pages/AdminSettings/AdminSettings'
import { useQuery } from '@apollo/react-hooks'
import Dialog from '@material-ui/core/Dialog'
import { GET_CSV_DATA, GET_CSV_REPORT } from '@queries'
import cx from 'classnames'
import format from 'date-fns/format'
import Button from '@material-ui/core/Button'
import config from '@config'
import Icon from '@components/Icon/Icon'
import UploadModal from '@modals/UploadModal/UploadModal'

import './AdminCsv.scss'

const getDate = date => {
  return format(new Date(Number(date)), 'HH:mm dd/MM/yyyy')
}

const AdminCsv = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const { data = {} } = useQuery(GET_CSV_DATA)
  const { data: reportData = {} } = useQuery(GET_CSV_REPORT, {
    pollInterval: 5000,
  })

  const { getCsvData = [] } = data
  const { getCsvReport = [] } = reportData

  const processDrawings = async () => {
    const res = await fetch(`${config.express}/build-csv`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
    })
    const json = await res.json()

    return json
  }

  return (
    <Fragment>
      <AdminSettings />
      <PaddingBox>
        <h1>Import Csv Data</h1>
        <div className="AdminCsv__flexWrapper">
          {getCsvData.map(item => (
            <p className="adminCsv__text" key={item.name}>
              <Icon icon={'file'} />
              {item.name} - {getDate(item.modified)}
            </p>
          ))}
        </div>
        <Button
          className="adminCsv__uploadButton"
          color="primary"
          variant="contained"
          onClick={() => setModalOpen(true)}
        >
          Upload Csvs
        </Button>
        <Button
          className="adminCsv__uploadButton"
          color="primary"
          variant="contained"
          onClick={processDrawings}
        >
          Import Csvs
        </Button>

        <h1 className="AdminBuildings__lowerTitle">Report</h1>

        <div className="AdminBuildings__reportContainer">
          {[...getCsvReport].reverse().map((item, index) => (
            <p
              className={cx({
                'AdminBuildings__message--error': !item.isSuccess,
              })}
              key={index}
            >{`${getDate(item.date)} - ${item.message}`}</p>
          ))}
        </div>
      </PaddingBox>
      <Dialog
        open={modalOpen}
        onClose={() => setModalOpen(!modalOpen)}
        maxWidth="sm"
        fullWidth
      >
        <UploadModal fileType="csv" closeModal={() => setModalOpen(false)} />
      </Dialog>
    </Fragment>
  )
}

AdminCsv.propTypes = {}

export default AdminCsv
