import React from 'react'
import { NavLink } from 'react-router-dom'
import Icon from '@components/Icon/Icon'

import './AdminSettings.scss'

const AdminSettings = () => {
  return (
    <div className="AdminSettings__container">
      <div className="AdminSettings__nav">
        <h1>Settings</h1>
        <NavLink
          className="AdminSettings__link"
          activeClassName="AdminSettings__link--active"
          to="/admin-buildings"
        >
          <Icon icon={'city-buildings'} />
          Import Drawings
        </NavLink>
        <NavLink
          className="AdminSettings__link"
          activeClassName="AdminSettings__link--active"
          to="/admin-csv"
        >
          <Icon icon={'database-import'} />
          Import Data
        </NavLink>
        <NavLink
          className="AdminSettings__link"
          activeClassName="AdminSettings__link--active"
          to="/admin-users"
        >
          <Icon icon={'user'} />
          Users
        </NavLink>
      </div>
    </div>
  )
}

AdminSettings.propTypes = {}

export default AdminSettings
