import React, { Fragment, useState, useEffect } from 'react'
import PaddingBox from '@components/PaddingBox/PaddingBox'
import AdminSettings from '@pages/AdminSettings/AdminSettings'
import { useQuery } from '@apollo/react-hooks'
import Dialog from '@material-ui/core/Dialog'
import { GET_DRAWING_DATA, GET_REPORT } from '@queries'
import cx from 'classnames'
import format from 'date-fns/format'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import config from '@config'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Icon from '@components/Icon/Icon'
import Chip from '@material-ui/core/Chip'
import { CircularProgress } from '@material-ui/core'
import UploadModal from '@modals/UploadModal/UploadModal'

import './AdminBuildings.scss'

const getDate = date => {
  return format(new Date(Number(date)), 'HH:mm dd/MM/yyyy')
}

const AdminBuildings = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const [checked, setChecked] = useState([])
  const { data = {}, refetch: refetchDrawings } = useQuery(GET_DRAWING_DATA)
  const { data: reportResponce = {}, refetch } = useQuery(GET_REPORT, {
    pollInterval: 5000,
  })

  const { getAllDrawings = [] } = data
  const { getReport = [] } = reportResponce
  const { isRunning, reports = [] } = getReport

  const applyChecked = ref => {
    if (checked.includes(ref)) {
      setChecked(checked.filter(item => item !== ref))
    } else {
      setChecked([...checked, ref])
    }
  }

  const processDrawings = async () => {
    if (isRunning) {
      return false
    }
    const res = await fetch(`${config.express}/build-forge-models`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
      body: JSON.stringify({
        buildings: checked,
      }),
    })
    await res.json()
    setChecked([])
    refetch()
  }

  useEffect(() => {
    refetchDrawings()
  }, [isRunning, refetchDrawings])

  const newDrawings = (modified, drawings) => {
    const count = (drawings.filter(item => item.LastModified > modified) || [])
      .length

    if (count > 0) {
      const text = count > 1 ? ' Updates' : ' Update'
      const label = count + text

      return (
        <div className="AdminBuildings__chip">
          <Chip color="primary" size="small" label={label} />
        </div>
      )
    }
  }

  return (
    <Fragment>
      <AdminSettings />
      <PaddingBox>
        <h1>Import buildings</h1>
        <div className="AdminBuildings__flexWrapper">
          {getAllDrawings.map(({ building, modified, drawings }) => {
            return (
              <div className="AdminBuildings__Wrapper" key={building}>
                <div
                  key={building}
                  className="AdminBuildings__buildingContainer"
                  onClick={() => applyChecked(building)}
                >
                  <Checkbox
                    checked={checked.includes(building)}
                    value="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </div>
                <div className="AdminBuildings__buildingContent">
                  <ExpansionPanel>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <h2 className="AdminBuildings__buildingTitle">
                        {building}
                      </h2>
                      {newDrawings(modified, drawings)}
                      <div className="AdminBuildings__buildingModified">
                        {`Building Updated at: ${getDate(modified)}`}
                      </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <div>
                        {drawings.map(subItem => (
                          <div
                            key={subItem.Key}
                            className={cx('AdminBuildings__drawingTitle')}
                          >
                            <Icon icon={'file'} />
                            {`${subItem.Key} - ${getDate(
                              subItem.LastModified,
                            )} `}
                            {subItem.LastModified > modified && (
                              <div className="AdminBuildings__chip">
                                <Chip
                                  color="primary"
                                  size="small"
                                  label="New"
                                />
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </div>
              </div>
            )
          })}
        </div>
        <Button
          className="adminBuilding__uploadButton"
          color="primary"
          variant="contained"
          onClick={() => setModalOpen(true)}
        >
          Upload Dwgs
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={processDrawings}
          disabled={isRunning}
        >
          {isRunning && (
            <CircularProgress
              color="white"
              size="16px"
              style={{ marginRight: '10px' }}
            />
          )}
          Update Buildings
        </Button>

        <h1 className="AdminBuildings__lowerTitle">Report</h1>

        <div className="AdminBuildings__reportContainer">
          {[...reports].reverse().map((item, index) => (
            <p
              className={cx({
                'AdminBuildings__message--error': !item.isSuccess,
              })}
              key={index}
            >{`${getDate(item.date)} - ${item.message}`}</p>
          ))}
        </div>
      </PaddingBox>
      <Dialog
        open={modalOpen}
        onClose={() => setModalOpen(!modalOpen)}
        maxWidth="sm"
        fullWidth
      >
        <UploadModal fileType="dwg" closeModal={() => setModalOpen(false)} />
      </Dialog>
    </Fragment>
  )
}

AdminBuildings.propTypes = {}

export default AdminBuildings
