import React, { Fragment } from 'react'
import forge from '@forge'
import groupBy from '@helpers/groupBy'
import { connect } from 'react-redux'
import { updateMenuState, updateFiltersState } from '@redux/actions'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

const sumData = (data, target) =>
  data.reduce((acc, item) => {
    acc = acc + Number(item[target])
    return acc
  }, 0)

const roundValue = val => sumData(val, 'area').toFixed(2)

const RoomInfoTabsChild = ({
  data,
  target,
  title,
  proccessModal,
  senarioList = [],
  menus,
  updateStore,
  colorCodes,
  labels,
  editDisabled,
}) => {
  const { activeScenario, activeScenarioPrev, activeScenarioNext } = menus

  const colorList = colorCodes.filter(item => item.type === target)

  const { previous = [], current = [], next = [] } = data || {}
  const mergePast = groupBy(previous, target)
  const mergeCurrent = groupBy(current, target)
  const mergeFuture = groupBy(next, target)

  const senarioListForSelect = senarioList.map(item => ({
    value: item._id,
    key: `${item.name}`,
  }))

  const indexOfActive = senarioListForSelect.findIndex(
    item => item.value === activeScenario,
  )

  const senarioInPast =
    senarioListForSelect.filter((item, index) => index < indexOfActive) || []
  const senarioInFuture =
    senarioListForSelect.filter((item, index) => index > indexOfActive) || []

  // make all uniq key list
  const keyList = [
    ...new Set([
      ...Object.keys(mergePast),
      ...Object.keys(mergeCurrent),
      ...Object.keys(mergeFuture),
    ]),
  ]

  const getArrow = (current, value) => {
    switch (true) {
      case value > current:
        return Number(value).toLocaleString() + '↑'
      case value < current:
        return Number(value).toLocaleString() + '↓'
      default:
        return Number(value).toLocaleString()
    }
  }

  return (
    <Fragment>
      <table className="roomData__table">
        <thead>
          <tr>
            <th>Name</th>
            <th>
              {!editDisabled ? (
                <Select
                  value={activeScenarioPrev}
                  onChange={e =>
                    updateStore({ activeScenarioPrev: e.target.value })
                  }
                >
                  <MenuItem value={''}>None</MenuItem>
                  {senarioInPast.map(({ value, key }) => (
                    <MenuItem value={value} key={'1' + key}>
                      {key}
                    </MenuItem>
                  ))}
                </Select>
              ) : (
                activeScenarioPrev
              )}
            </th>
            <th>Current (m²)</th>
            <th>
              {!editDisabled ? (
                <Select
                  value={activeScenarioNext}
                  onChange={e =>
                    updateStore({ activeScenarioNext: e.target.value })
                  }
                >
                  <MenuItem value={''}>None</MenuItem>
                  {senarioInFuture.map(({ value, key }) => (
                    <MenuItem value={value} key={'2' + key}>
                      {key}
                    </MenuItem>
                  ))}
                </Select>
              ) : (
                activeScenarioNext
              )}
            </th>
            <th>Legend</th>
          </tr>
        </thead>
        <tbody>
          {keyList
            .sort((a, b) => a.localeCompare(b))
            .map(key => {
              const idList = mergeCurrent[key]
                ? mergeCurrent[key].map(({ room_uuid }) => room_uuid)
                : []
              const colorItem = colorList.find(item => item.name === key) || {}
              const { r, g, b } = colorItem

              const pastValue = mergePast[key]
                ? roundValue(mergePast[key])
                : undefined

              const currentValue = mergeCurrent[key]
                ? roundValue(mergeCurrent[key])
                : undefined

              const futureValue = mergeFuture[key]
                ? roundValue(mergeFuture[key])
                : undefined

              const correctNode =
                labels.find(item => item[target] === key) || {}
              const textLabel =
                correctNode.name ||
                correctNode.description ||
                correctNode.pr_rm_desc
              const joinedLabel = `${key || ''} - ${textLabel || ''}`

              return (
                <tr onClick={() => forge.openListByMetaId(idList)} key={key}>
                  <td className="clip__text" alt={joinedLabel}>
                    <span>{joinedLabel}</span>
                  </td>

                  <td>
                    <span className="roomData__text">
                      {pastValue
                        ? `${getArrow(currentValue, pastValue)} (${
                            mergePast[key].length
                          })`
                        : '-'}
                    </span>
                  </td>

                  <td>
                    {currentValue
                      ? `${Number(currentValue).toLocaleString()} (${
                          mergeCurrent[key].length
                        })`
                      : '-'}
                  </td>

                  <td>
                    <span className="roomData__text">
                      {futureValue
                        ? `${getArrow(currentValue, futureValue)} (${
                            mergeFuture[key].length
                          })`
                        : '-'}
                    </span>
                  </td>
                  <td>
                    <div
                      className="roomInfo__key"
                      style={{ backgroundColor: `rgb(${r},${g},${b}` }}
                      onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                        proccessModal({ name: key, ...colorItem, target })
                      }}
                    />
                  </td>
                </tr>
              )
            })}
        </tbody>
      </table>
    </Fragment>
  )
}

RoomInfoTabsChild.propTypes = {}

const mapStateToProps = ({ filters, menus }) => ({
  filters,
  menus,
})

const mapDispatchToProps = d => ({
  updateStore: obj => d(updateMenuState(obj)),
  openFilter: obj => d(updateFiltersState(obj)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RoomInfoTabsChild)
