import React, { useState, useEffect } from 'react'
import { string } from 'prop-types'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Spacer from '@components/Spacer/Spacer'
import DialogContent from '@material-ui/core/DialogContent'
import { useMutation } from 'react-apollo'
import stringifyErrors from '@helpers/stringifyErrors'
import { withSnackbar } from 'notistack'
import { withApollo } from 'react-apollo'
import { GET_BUILDINGS } from '@queries'
import ImageUploader from '@components/ImageUploader/ImageUploader'
import config from '@config'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useQuery } from '@apollo/react-hooks'
import { GET_URNS } from '@queries'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import {
  DELETE_BUILDING_MUTATION,
  NEW_BUILDING_MUTATION,
  EDIT_BUILDING_MUTATION,
} from '@queries/mutations'
import buildingCoordinates from '../../helpers/buildingCoordinates'

import './NewBuildingModal.scss'

const EMPTY_STATE = {
  name: '',
  description: '',
  address: '',
  cord_x: 0,
  cord_y: 0,
  cord_z: 0,
  rotation: 0,
  _id: '',
  picture: '',
  urn: 0,
}

const NewBuildingModal = ({
  client,
  enqueueSnackbar,
  closeModal,
  projectId,
  activeBuilding,
  callback,
}) => {
  const [loading, setLoading] = useState(false)
  const [deleteMutation] = useMutation(DELETE_BUILDING_MUTATION)
  const [picture, setPicture] = useState()
  const [currentModel, setCurrentModel] = useState('')
  const [currentCoordinates, setCurrentCoordiantes] = useState({
    x: 0,
    y: 0,
    z: 0,
    r: 0,
  })

  const isEditMode = activeBuilding._id

  const [formVals, setFormVals] = useState(
    isEditMode
      ? {
          ...activeBuilding,
          ...activeBuilding.position,
          // had to rename this for left outer join so do this
          urn: activeBuilding.urn_id,
        }
      : EMPTY_STATE,
  )
  const {
    name,
    description,
    address,
    building_code,
    cord_x,
    cord_y,
    cord_z,
    rotation,
    _id,
    picture: activePicture,
    urn,
  } = formVals

  const [buildingMutation] = useMutation(
    isEditMode ? EDIT_BUILDING_MUTATION : NEW_BUILDING_MUTATION,
  )

  const updateFormVals = (target, val, model = null) => {
    if (model) {
      if (buildingCoordinates[model]) {
        setFormVals({
          ...formVals,
          [target]: val,
          cord_x: buildingCoordinates[model].x,
          cord_y: buildingCoordinates[model].y,
          cord_z: buildingCoordinates[model].z,
          rotation: buildingCoordinates[model].r,
        })
      } else {
        setFormVals({
          ...formVals,
          [target]: val,
          cord_x: 0,
          cord_y: 0,
          cord_z: 0,
          rotation: 0,
        })
      }
    } else {
      setFormVals({ ...formVals, [target]: val })
    }
  }

  console.log(formVals)

  useEffect(() => {
    console.log(currentModel)
    setCurrentCoordiantes(buildingCoordinates[currentModel])
    console.log(currentCoordinates)
  }, [currentModel])

  const newProjectAction = async e => {
    e.preventDefault()
    setLoading(true)

    try {
      const variables = {
        _id,
        project_id: projectId,
        name,
        description,
        address,
        building_code,
        cord_x,
        cord_y,
        cord_z,
        rotation,
        urn: urn ? Number(urn) : null,
      }

      if (picture && picture.length) {
        variables.imageUpload = picture[0]
      }

      await buildingMutation({ variables })
      enqueueSnackbar(isEditMode ? 'Building Edited' : 'Building Created', {
        variant: 'success',
      })

      callback()
      setFormVals(EMPTY_STATE)
      closeModal()
    } catch (e) {
      console.log('e', e)
      enqueueSnackbar(stringifyErrors(e.graphQLErrors), {
        variant: 'error',
      })
    }
  }

  const deleteProjectAction = async deleteId => {
    try {
      const res = await deleteMutation({
        variables: { _id: deleteId },
      })

      client.writeQuery({
        query: GET_BUILDINGS,
        variables: { project_id: projectId },
        data: {
          getBuildingsByProject: res.data.deleteBuilding,
        },
      })

      enqueueSnackbar('Project Deleted', {
        variant: 'success',
      })

      closeModal()
    } catch (e) {
      enqueueSnackbar(stringifyErrors(e.graphQLErrors), {
        variant: 'error',
      })
    }
  }

  const { data = {} } = useQuery(GET_URNS)

  const { getUrns = [] } = data
  const getUrnsSorted = [...getUrns].sort((a, b) =>
    a.building_code > b.building_code
      ? 1
      : b.building_code > a.building_code
      ? -1
      : 0,
  )

  return (
    <div>
      <DialogTitle>
        {isEditMode ? 'Edit Building' : 'Create New Building'}
        {isEditMode && (
          <Button color="secondary" onClick={() => deleteProjectAction(_id)}>
            Delete
          </Button>
        )}
      </DialogTitle>
      {!!activePicture && (
        <div
          className="editProject__cover"
          style={{
            backgroundImage: `url('${config.imageServer}/${encodeURI(
              activePicture,
            )}')`,
          }}
        />
      )}
      <DialogContent>
        <form onSubmit={newProjectAction}>
          <p>Building Cover Image</p>

          <ImageUploader
            callback={e => {
              console.log('e', e)
              setPicture(e)
            }}
          />
          <TextField
            label="Name"
            value={name}
            onChange={e => updateFormVals('name', e.target.value)}
            margin="normal"
            type="text"
            required
          />

          <TextField
            label="Description"
            value={description}
            onChange={e => updateFormVals('description', e.target.value)}
            margin="normal"
            type="text"
          />

          <TextField
            label="Address"
            value={address}
            onChange={e => updateFormVals('address', e.target.value)}
            margin="normal"
            type="text"
          />

          <div className="newBuilding_cords">
            <TextField
              label="X cord"
              value={cord_x || 0}
              onChange={e => updateFormVals('cord_x', Number(e.target.value))}
              margin="normal"
              type="number"
              required
            />

            <TextField
              label="Y cord"
              value={cord_y || 0}
              onChange={e => updateFormVals('cord_y', Number(e.target.value))}
              margin="normal"
              type="number"
              required
            />

            <TextField
              label="Z cord"
              value={cord_z || 0}
              onChange={e => updateFormVals('cord_z', Number(e.target.value))}
              margin="normal"
              type="number"
              required
            />
            <TextField
              label="Rotation"
              value={rotation || 0}
              onChange={e => updateFormVals('rotation', Number(e.target.value))}
              margin="normal"
              type="number"
              required
            />
          </div>

          <Spacer />

          <FormControl>
            <InputLabel id="select-label">Building Model</InputLabel>
            <Select
              labelId="select-label"
              value={urn}
              onChange={e => {
                updateFormVals(
                  'urn',
                  Number(e.target.value),
                  getUrnsSorted.filter(item => item._id === e.target.value)[0]
                    .building_code,
                )
              }}
            >
              {getUrnsSorted.map(item => (
                <MenuItem key={item._id} value={item._id}>
                  {item.building_code}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Spacer />

          <Button variant="contained" color="primary" type="submit">
            {loading ? (
              <CircularProgress color="secondary" size={20} />
            ) : isEditMode ? (
              'Save Building'
            ) : (
              'Create New Building'
            )}
          </Button>
        </form>
      </DialogContent>
    </div>
  )
}

NewBuildingModal.propTypes = {
  example: string,
}

export default withSnackbar(withApollo(NewBuildingModal))
