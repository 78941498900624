import React, { useState } from 'react'
import { string } from 'prop-types'
import Button from '@material-ui/core/Button'
import { GET_SCENARIOS } from '@queries'
import { DELETE_SCENARIO_DATE_MUTATION } from '@queries/mutations'
import { useQuery } from '@apollo/react-hooks'
import Dialog from '@material-ui/core/Dialog'
import NewScenario from '@modals/NewScenario/NewScenario'
import NewScenarioDate from '@modals/NewScenarioDate/NewScenarioDate'
import { withSnackbar } from 'notistack'
import format from 'date-fns/format'
import { connect } from 'react-redux'
import { updateMenuState } from '@redux/actions'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Spacer from '@components/Spacer/Spacer'
import parseISO from 'date-fns/parseISO'
import { useMutation } from 'react-apollo'
import stringifyErrors from '@helpers/stringifyErrors'
import forge from '@forge'
import Icon from '@components/Icon/Icon'

import './DrawInnerWrapper.scss'

const DrawInnerWrapper = ({ enqueueSnackbar, updateStore, menus }) => {
  const [subModalEditData, setSubModalEditData] = useState({})
  const [modalOpen, setModalOpen] = useState(false)
  const [modalSubOpen, setModalSubOpen] = useState(false)
  const { openProject: projectId, activeScenarioParent } = menus

  const [deleteDateMutation] = useMutation(DELETE_SCENARIO_DATE_MUTATION)

  const { data: scenarioData = {}, refetch } = useQuery(GET_SCENARIOS, {
    variables: { project_id: Number(projectId) },
  })

  const finalList = scenarioData.getScenarioByProject || []

  const active = finalList.find(item => item._id === activeScenarioParent) || {
    scenario_dates: [],
  }

  const deteDateMutationAction = async (e, _id) => {
    e.preventDefault()

    try {
      e.preventDefault()
      await deleteDateMutation({
        variables: { _id },
      })

      enqueueSnackbar('Event Deleted', {
        variant: 'success',
      })
    } catch (e) {
      enqueueSnackbar(stringifyErrors(e.graphQLErrors), {
        variant: 'error',
      })
    }
  }

  return (
    <div className="projectDraw__container">
      <div
        className="projectDraw__bg"
        onClick={() =>
          updateStore({
            drawIsOpen: false,
            subDrawIsOpen: false,
            scenarioDrawOpen: false,
          })
        }
      />

      <div className="projectDraw__nav">
        <div className="projectDraw__nav--scroll scenarioMenu__container drawInnerWrapper__topContainer">
          <h1 className="projectDraw__header drawInnerWrapper__header">
            Scenario Planner
            <span>
              {activeScenarioParent && (
                <Button
                  color="secondary"
                  onClick={() => {
                    setSubModalEditData(active)
                    setModalOpen(true)
                  }}
                >
                  Edit
                </Button>
              )}
              <Button color="secondary" onClick={() => setModalOpen(true)}>
                New Scenario
              </Button>
            </span>
          </h1>

          <Spacer small />

          <div className="drawInnerWrapper__selectContainer">
            <FormControl fullWidth>
              <InputLabel id="select-label">Scenario</InputLabel>
              <Select
                onChange={e => {
                  updateStore({ activeScenarioParent: e.target.value })
                  updateStore({ activeScenario: null })
                }}
                value={activeScenarioParent}
              >
                <MenuItem value={false}>None</MenuItem>
                {finalList.map(item => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <Spacer medium />

          <h2 className="projectDraw__header">
            Events
            {active._id && (
              <Button
                color="secondary"
                onClick={() => {
                  setModalSubOpen(true)
                }}
              >
                New Event
              </Button>
            )}
          </h2>
          <Spacer small />
          <div className="drawInnerWrapper__childrenContainer">
            {active.scenario_dates.length > 0 ? (
              active.scenario_dates.map(item => (
                <div
                  className="drawInnerWrapper__eventCell"
                  key={item._id}
                  onClick={() => {
                    updateStore({
                      activeScenario: item._id,
                    })
                    // this updates the forge extention with correct scenario ID's
                    forge.updateScenarioApi()
                    updateStore({
                      drawIsOpen: false,
                      subDrawIsOpen: false,
                      scenarioDrawOpen: false,
                    })
                  }}
                >
                  <div className="drawInnerWrapper__titleCell">
                    <h2>
                      <span
                        style={{
                          color: `rgb(${item.r},${item.g},${item.b})`,
                        }}
                      >
                        <Icon icon={'tag-window-2'} />
                      </span>
                      {item.name}
                    </h2>
                    <h2>
                      {item.date
                        ? format(parseISO(item.date), 'dd MMM yyyy')
                        : ''}
                    </h2>
                  </div>
                  <div className="drawInnerWrapper__optionsContainer">
                    <p>{item.comments}</p>
                    <div className="drawInnerWrapper__options">
                      <span
                        onClick={e => {
                          e.stopPropagation()
                          setModalSubOpen(true)
                          setSubModalEditData(item)
                        }}
                      >
                        Edit
                      </span>{' '}
                      -{' '}
                      <span
                        onClick={e => {
                          e.stopPropagation()
                          deteDateMutationAction(e, item._id)
                        }}
                      >
                        Delete
                      </span>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No Scenario Selected</p>
            )}
          </div>
        </div>
      </div>

      <Dialog
        open={modalOpen}
        onClose={() => setModalOpen(!modalOpen)}
        maxWidth="sm"
        fullWidth
      >
        <NewScenario
          closeModal={() => {
            setModalOpen(false)
            setSubModalEditData({})
          }}
          resetChild={() => {
            updateStore({
              activeScenario: false,
            })
            updateStore({
              activeScenarioParent: false,
            })
            setSubModalEditData({})
          }}
          projectId={projectId}
          data={subModalEditData}
          callback={refetch}
        />
      </Dialog>
      <Dialog
        open={modalSubOpen}
        onClose={() => {
          setModalSubOpen(!modalSubOpen)
          setSubModalEditData({})
        }}
        maxWidth="sm"
        fullWidth
      >
        <NewScenarioDate
          closeModal={() => {
            setModalSubOpen(false)
            setSubModalEditData({})
          }}
          activeScenarioParent={activeScenarioParent}
          projectId={projectId}
          data={subModalEditData}
          callback={refetch}
        />
      </Dialog>
    </div>
  )
}

DrawInnerWrapper.propTypes = {
  example: string,
}

const mapStateToProps = ({ menus }) => ({ menus })

const mapDispatchToProps = d => ({
  updateStore: obj => d(updateMenuState(obj)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withSnackbar(DrawInnerWrapper))
