import React, { useState, useEffect, Fragment } from 'react'
import { object } from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Popover from '@material-ui/core/Popover'
import { withRouter } from 'react-router-dom'
import cx from 'classnames'
import forge from '@forge'
import { updateMenuState } from '@redux/actions'
import Icon from '@components/Icon/Icon'
import Logo from '@assets/logo.svg'
import config from '@config'

import './LeftNav.scss'

const LeftNav = ({ history, updateStore, menus }) => {
  const {
    expandDrawOpen,
    drawIsOpen,
    scenarioDrawOpen,
    openProject,
    reportDrawOpen,
  } = menus
  const currentPath = history.location.pathname
  const isViewer = currentPath.includes('/viewer')

  const logout = () => {
    const email = localStorage.getItem('email')
    localStorage.removeItem('email')
    localStorage.removeItem('token')
    window.location.replace(`${config.logout}?email=${email}`)
    //history.push('/login')
  }

  useEffect(() => {
    if (!isViewer) {
      forge.resizeCanvas()
      updateStore({
        drawIsOpen: false,
        subDrawIsOpen: false,
        expandDrawOpen: false,
        scenarioDrawOpen: false,
        reportDrawOpen: false,
      })
    }
  }, [isViewer, updateStore])

  return (
    <div className="leftNav__container">
      <div className="leftNav__container--top">
        <div className={cx('leftNav__svg', 'leftNav__logo')}>
          <img src={Logo} alt="Logo" />
        </div>
        <div
          className={cx('leftNav__subNav', {
            'leftNav__svg--active': isViewer,
          })}
        >
          <div className="leftNav__menuItem--top">
            <Link
              to="/viewer"
              className={drawIsOpen ? 'leftNav_menuItem--active' : ''}
              onClick={() => {
                updateStore({
                  drawIsOpen: !drawIsOpen,
                  subDrawIsOpen: false,
                  scenarioDrawOpen: false,
                  reportDrawOpen: false,
                })
                forge.resizeCanvas()
              }}
            >
              <Icon icon="city-buildings" noPad />
            </Link>
            <div className="leftNav__iconText">Projects</div>
          </div>
          {openProject && (
            <div className="leftNav__menuItem--top">
              <Link
                to="/viewer"
                className={expandDrawOpen ? 'leftNav_menuItem--active' : ''}
                onClick={() => {
                  updateStore({
                    expandDrawOpen: !expandDrawOpen,
                    drawIsOpen: false,
                    subDrawIsOpen: false,
                    scenarioDrawOpen: false,
                    reportDrawOpen: false,
                  })
                  forge.resizeCanvas()
                }}
              >
                <Icon
                  icon="stacked-organizational-chart-highlighted-parent-node"
                  noPad
                />
              </Link>
              <div className="leftNav__iconText">Browser</div>
            </div>
          )}
          {openProject && (
            <div className="leftNav__menuItem--top">
              <Link
                to="/viewer"
                className={scenarioDrawOpen ? 'leftNav_menuItem--active' : ''}
                onClick={() => {
                  updateStore({
                    expandDrawOpen: false,
                    drawIsOpen: false,
                    subDrawIsOpen: false,
                    reportDrawOpen: false,
                    scenarioDrawOpen: !scenarioDrawOpen,
                  })
                  forge.resizeCanvas()
                }}
              >
                <Icon icon="event" noPad />
              </Link>
              <div className="leftNav__iconText">Planner</div>
            </div>
          )}
          {openProject && (
            <div className="leftNav__menuItem--top">
              <Link
                to="/viewer"
                className={reportDrawOpen ? 'leftNav_menuItem--active' : ''}
                onClick={() => {
                  updateStore({
                    expandDrawOpen: false,
                    drawIsOpen: false,
                    subDrawIsOpen: false,
                    scenarioDrawOpen: false,
                    reportDrawOpen: !reportDrawOpen,
                  })
                  forge.resizeCanvas()
                }}
              >
                <Icon icon="graph" noPad />
              </Link>
              <div className="leftNav__iconText">Report</div>
            </div>
          )}
        </div>
      </div>
      <div className="leftNav__container--bottom">
        <div className="leftNav__menuItem--bottom">
          <Link
            to="/admin-buildings"
            className={cx({
              'leftNav__link--active': currentPath.includes('/admin'),
            })}
          >
            <Icon icon="settings" noPad />
          </Link>
          <div className="leftNav__iconText">Settings</div>
        </div>
        <div className="leftNav__menuItem--bottom">
          <Link
            to="#"
            onClick={logout}
            // needs a to
            className={cx({
              'leftNav__link--active': currentPath.includes('/user'),
            })}
          >
            <Icon icon="sign-out" noPad />
          </Link>
          <div className="leftNav__iconText">Sign Out</div>
        </div>
      </div>
    </div>
  )
}

LeftNav.propTypes = { history: object }

const mapStateToProps = ({ menus }) => ({ menus })

const mapDispatchToProps = d => ({
  updateStore: obj => d(updateMenuState(obj)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(LeftNav))
